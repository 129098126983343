import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    timeFormatterTwelveHours (value) {
      if (value) {
        return moment(value).format('LT')
      }
      return null
    },

    dateTimeFormatterLong (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY - LT')
      }
      return null
    },

    dateFormatterLong (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY')
      }
      return null
    },

    dateFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    numberFormatter (value) {
      if (value) {
        return numeral(value).format('0,0.00')
      }
      return null
    },

    yesOrNo (value) {
      if (value !== null || value !== '') {
        return Number(value) === 1 ? 'Yes' : 'No'
      }

      return null
    },

    objectToUrl (payload, includeNoValues = true) {
      const payloadKeys = Object.keys(payload)

      if (payloadKeys.length <= 0) {
        return ''
      }

      return payloadKeys.map(
        payloadKey => {
          if (typeof payload[payloadKey] === 'undefined' || payload[payloadKey] === 'null' || payload[payloadKey] === null) {
            return includeNoValues ? `${payloadKey}=` : null
          }

          if (Array.isArray(payload[payloadKey]) > 0) {
            return this.arrayToUrlLists(
              Array.from(payload[payloadKey]),
              payloadKey
            )
          }

          return `${payloadKey}=${payload[payloadKey]}`
        }
      ).filter(value => value).join('&').toString()
    },

    arrayToUrlLists (values, payloadKey = 'lists') {
      if (Array.isArray(values) <= 0) {
        return ''
      }

      return values.filter(value => value).map(
        value => `${payloadKey}[]=${value}`
      ).join('&').toString()
    }
  }
}
